import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import { setIsActive } from "../../Stores/Slices/add-cart-modal";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../Stores/Slices/cart.slice";

SwiperCore.use([Navigation, Thumbs]);

function AddToCartModal(props) {
  const [isActiveModal, setIsActiveModal] = useState(false);

  const addCartModal = useSelector((state) => state.addCartModal);
  const dispatch = useDispatch();

  const {
    isActive,
    imageList,
    nameProduct,
    retailPrice,
    productCode,
    releaseDate,
    descProduct,
    brand,
  } = props;

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const selectSize = () => {
    let listSizes = document.querySelectorAll(".list-sizes li");
    listSizes.forEach((item) => {
      item.addEventListener("click", () => {
        clearSelectedSize();
        item.classList.add("active");
      });
    });
  };

  const clearSelectedSize = () => {
    document.querySelectorAll(".list-sizes li").forEach((item) => {
      if (item.classList.contains("active")) {
        item.classList.remove("active");
      }
    });
  };

  const setInActiveModal = () => {
    dispatch(setIsActive(false));
  }

  const checkSelectedSize = async (callback) => {
    let isSelected = false;
    let sizeSelected = -1;
    document.querySelectorAll(".list-sizes li").forEach((li) => {
      if (li.classList.contains('active')) {
        sizeSelected = li.textContent;
        isSelected = true;
      }
    });

    callback(isSelected, sizeSelected);
  }

  const handleOrderNow = async () => {
    checkSelectedSize((selected, sizeSelected) => {
      if (selected === false) {
        toast.warn(`Please select your size.`)
        return;
      }

      dispatch(addProduct({ ...addCartModal?.productInfo, sizeSelected }))
    })
  }

  useEffect(() => {
    selectSize();
  }, [addCartModal]);

  return (
    <div className={"add-to-cart-modal " + (addCartModal.isActive === true ? "active" : "")}>
      <div className="modal-overlay" onClick={() => {
        dispatch(setIsActive({ isActive: false, productInfo: {} }))
      }}>
        <div className="modal" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          <div className="modal-left">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={20}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              className="swiper-main"
            >
              {addCartModal?.productInfo?.imageLinks?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={20}
              slidesPerView={3}
              freeMode={true}
              watchSlidesProgress={true}
              className="swiper-thumb"
            >
              {addCartModal?.productInfo?.imageLinks?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={item} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="modal-right">
            <div className="brand-name">
              <p>{addCartModal?.productInfo?.brand}</p>
              <p className="release-date">{addCartModal?.productInfo?.releaseDate}</p>
            </div>
            <div className="shoes-name">{addCartModal?.productInfo?.shoeName}</div>
            <div className="shoes-colorway">{addCartModal?.productInfo?.colorway}</div>
            <div className="shoes-prices">${addCartModal?.productInfo?.retailPrice}</div>
            <div className="shoes-code">Product code: {addCartModal?.productInfo?.styleID}</div>
            <div className="product-sizes">
              <p>Size:</p>
              <ul className="list-sizes">
                {/* {
                                sizeList.map((item) => {
                                    return (
                                        <li><span>item.size</span></li>
                                    )
                                })
                            } */}
                <li>
                  <span>40</span>
                </li>
                <li>
                  <span>42</span>
                </li>
                <li>
                  <span>42.5</span>
                </li>
                <li>
                  <span>44</span>
                </li>
                <li>
                  <span>45</span>
                </li>
              </ul>
            </div>
            <div className="product-control">
              <div className="product-quantity">
                <button
                  className="sub-quantity"
                  onClick={() => {
                    if (quantity === 1) {
                      return;
                    }

                    setQuantity(quantity - 1);
                  }}
                >
                  <p>-</p>
                </button>
                <input
                  name="quantity"
                  id="quantity"
                  type="number"
                  min={1}
                  value={quantity}
                  max={100}
                  readOnly
                />
                <button
                  className="add-quantity"
                  onClick={() => {
                    if (quantity === 99) {
                      return;
                    }

                    setQuantity(quantity + 1);
                  }}
                >
                  <p>+</p>
                </button>
              </div>

              <button className="product-order" onClick={() => handleOrderNow()}>
                <p>Order now</p>
              </button>
            </div>
          </div>
          <button className="close-modal" onClick={() => {
            dispatch(setIsActive({ isActive: false, productInfo: {} }))
          }}>
            <svg id="Capa_1" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z" /><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z" /></g></g></svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddToCartModal;
