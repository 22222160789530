import React from "react";
import "./index.scss";
function LineWave() {
  return (
    <div className="wave-line">
      <div className="container">
        <div className="wave"></div>
      </div>
    </div>
  );
}

export default LineWave;
