import React, { useCallback } from "react";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import { addProduct, removeProduct } from "../../Stores/Slices/cart.slice";
import { setIsActive } from "../../Stores/Slices/add-cart-modal";
import { setIsActiveLoading } from "../../Stores/Slices/loading.slice";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";

function ProductItem(props) {
  // const { img, productName, price } = props.data;
  const cartList = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const addCartModal = useSelector((state) => state.addCartModal);
  // const dispatchAddCartModal = useDispatch();

  let history = useHistory();
  const goToLink = useCallback(() => {
    history.push(`/products/${urlKey}`);
  }, [history]);

  const {
    imageLinks,
    retailPrice,
    shoeName,
    thumbnail,
    urlKey,
    brand,
    styleID,
    colorway,
    releaseDate,
    description
  } = props.data;

  const getProductInfoByStyleID = async (styleID) => {
    return await axios.get(`https://sneaker-api.onrender.com/id/${styleID}/prices`)
      .then((res) => {

        let isActive = true;

        let newState = { isActive: isActive, productInfo: res.data };

        dispatch(setIsActiveLoading(false))
        return newState;
      })
  }

  const handleSetActiveAddCartToModal = async () => {
    dispatch(setIsActiveLoading(true))
    let productInfo = {
      styleID,
    };

    let newState = await getProductInfoByStyleID(styleID);

    dispatch(setIsActive(newState))
  }

  return (
    <div className="product">
      <div className="img">
        <img src={thumbnail} alt="" />
        <ul className="action-list">
          <li
            className="action-item"
            data-type="details"
            onClick={() => {
              goToLink();
            }}
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 488.85 488.85"
            >
              <g>
                <path
                  d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2
		s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025
		c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3
		C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7
		c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>

            <Link to={`/products/${urlKey}`} onClick={() => { }}>
              View Details
            </Link>
          </li>
          <li
            className="action-item"
            data-type="cart"
            // onClick={() => dispatch(addProduct(props.data))}
            onClick={() => {
              handleSetActiveAddCartToModal()
            }}
          >
            <svg
              id="Bold"
              height="512"
              viewBox="0 0 24 24"
              width="512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m19.091 19h-8.994c-1.297 0-2.447-.851-2.798-2.07l-3.04-10.301c-.108-.373-.467-.629-.877-.629h-2.382c-.553 0-1-.448-1-1s.447-1 1-1h2.382c1.297 0 2.446.851 2.797 2.07l.569 1.93h15.295c.624 0 1.216.297 1.583.795.364.494.469 1.112.288 1.696l-2.063 6.548c-.404 1.182-1.511 1.961-2.76 1.961zm2.953-9h.01z" />
              <path d="m11 24c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.001v.001z" />
              <path d="m18 24c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.001v.001z" />
            </svg>
            <a href="">Add to Cart</a>
          </li>
          <li className="action-item" data-type="wishlish">
            <svg
              id="Capa_1"
              height="512"
              viewBox="0 0 512 512"
              width="512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m376 43.839c-60.645 0-99.609 39.683-120 75.337-20.391-35.654-59.355-75.337-120-75.337-76.963 0-136 58.945-136 137.124 0 84.771 73.964 142.5 184.413 229.907 54.082 42.761 57.557 46.011 71.587 57.29 11.45-9.205 17.787-14.751 71.587-57.29 110.449-87.407 184.413-145.136 184.413-229.907 0-78.178-59.037-137.124-136-137.124z" />
            </svg>
            <a href="">Add to Wishlist</a>
          </li>
        </ul>
      </div>
      <div className="content">
        <div className="product-name">
          <h3>{shoeName}</h3>
        </div>
        <div className="price">
          <h2>${retailPrice}</h2>
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
