import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./index.scss";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addProduct } from "../../Stores/Slices/cart.slice";

SwiperCore.use([Navigation, Thumbs]);

function ProductInfo(props) {
  const {
    imageList,
    // nameProduct,
    // retailPrice,
    // productCode,
    // releaseDate,
    // descProduct,
    // brand,
    // colorway,
    productInfo
  } = props;
  const [sizeList, setSizeList] = useState([]);
  // useEffect(() => {
  //     let sizeListClone = [];
  //     Object.keys(info.resellPrices).forEach((key) => {
  //         let price = info.resellPrices[key];
  //         let size = key;

  //         sizeListClone.push({ size, price })
  //     });

  //     setSizeList(sizeListClone)
  // }, [])
  const dispatch = useDispatch();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const selectSize = () => {
    let listSizes = document.querySelectorAll(".list-sizes li");
    listSizes.forEach((item) => {
      item.addEventListener("click", () => {
        clearSelectedSize();
        item.classList.add("active");
      });
    });
  };

  const clearSelectedSize = () => {
    document.querySelectorAll(".list-sizes li").forEach((item) => {
      if (item.classList.contains("active")) {
        item.classList.remove("active");
      }
    });
  };

  const checkSelectedSize = async (callback) => {
    let isSelected = false;
    let sizeSelected = -1;
    document.querySelectorAll(".list-sizes li").forEach((li) => {
      if (li.classList.contains('active')) {
        sizeSelected = li.textContent;
        isSelected = true;
      }
    });

    callback(isSelected, sizeSelected);
  }

  const handleOrderNow = async () => {
    checkSelectedSize((selected, sizeSelected) => {
      if (selected === false) {
        toast.warn(`Please select your size.`)
        return;
      }

      dispatch(addProduct({ ...productInfo, sizeSelected }))
    })
  }

  useEffect(() => {
    selectSize();
  }, []);

  return (
    <div className="product-info">
      <div className="container">
        <div className="product-info__left">
          <Swiper
            loop={true}
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            className="product-info__swiper"
          >
            {imageList.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            loop={true}
            direction="vertical"
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={3}
            watchSlidesProgress={true}
            className="product-info__swiperThumb"
          >
            {imageList.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="product-info__right">
          <div className="product-brand">
            <p>{productInfo.brand}</p>
            <p className="date-release">Release: {productInfo.releaseDate}</p>
          </div>
          <div className="product-name">
            <p>{productInfo.shoeName}</p>
          </div>

          <div className="product-colorway">
            <p>COLOR: <span>{productInfo.colorway}</span></p>
          </div>

          <div className="product-price">
            <p>{productInfo.retailPrice}$</p>
          </div>

          <div className="line"></div>

          <div className="product-code">Product code: {productInfo.styleID}</div>

          <div className="product-sizes">
            <p>Size:</p>
            <ul className="list-sizes">
              {/* {
                                sizeList.map((item) => {
                                    return (
                                        <li><span>item.size</span></li>
                                    )
                                })
                            } */}
              <li>
                <span>40</span>
              </li>
              <li>
                <span>42</span>
              </li>
              <li>
                <span>42.5</span>
              </li>
              <li>
                <span>44</span>
              </li>
              <li>
                <span>45</span>
              </li>
            </ul>
          </div>

          <div className="product-control">
            <div className="product-quantity">
              <button
                className="sub-quantity"
                onClick={() => {
                  if (quantity === 1) {
                    return;
                  }

                  setQuantity(quantity - 1);
                }}
              >
                <p>-</p>
              </button>
              <input
                name="quantity"
                id="quantity"
                type="number"
                min={1}
                value={quantity}
                max={100}
                readOnly
              />
              <button
                className="add-quantity"
                onClick={() => {
                  if (quantity === 99) {
                    return;
                  }

                  setQuantity(quantity + 1);
                }}
              >
                <p>+</p>
              </button>
            </div>

            <button className="product-order" onClick={() => handleOrderNow()}>
              <p>Order now</p>
            </button>
          </div>

          <div className="line"></div>

          <div className="product-desc">
            <p>Descriptions:</p>
            <p>{productInfo.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductInfo;
