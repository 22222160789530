import React, { Fragment, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFlip,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "swiper/components/effect-flip";
import "./index.scss";
import "swiper/components/scrollbar/scrollbar.min.css";
// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);
function Banner(props) {
  let swiperCursor;
  const handleSwiper = (e) => {
    swiperCursor = e;
  };
  return (
    <div className="container">
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        autoplay={{ delay: 4000 }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{ clickable: true }}
        onSlideChange={(e) => {
          handleSwiper(e);
        }}
        onSwiper={(swiper) => {
          swiperCursor = swiper;
        }}
      >
        <SwiperSlide>
          <img src="./img/sneaker-house-banner-2.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./img/sneaker-house-banner-3.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./img/sneaker-house-banner.jpg" alt="" />
        </SwiperSlide>
        <div className="swiper-pagination" />
        <div
          className="swiper-button-prev"
          onClick={() => {
            console.log('prev')
            console.log(swiperCursor.isBeginning)
            console.log(swiperCursor.isEnd)
            // if (swiperCursor.isBeginning === false) {
            //   swiperCursor.slidePrev();
            // } else {
            //   swiperCursor.slideToLoop(2);
            // }
          }}
        />
        <div
          className="swiper-button-next"
          onClick={() => {
            console.log('next')
            console.log(swiperCursor.isBeginning)
            console.log(swiperCursor.isEnd)
            // if (swiperCursor.isEnd === false) {
            //   swiperCursor.slideNext();
            // } else {
            //   swiperCursor.slideToLoop(0);
            // }
          }}
        />
      </Swiper>
    </div>
  );
}

export default Banner;
