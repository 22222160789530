import React from 'react'
import { useSelector } from 'react-redux';
import './index.scss'

function Loading(props) {
    // const { isActive } = props
    const loading = useSelector((state) => state.loading);
    return (
        <div style={{ display: loading === true ? "flex" : "none" }} className="loading-overlay">
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div >
    )
}

export default Loading
