import React, { useEffect, useState } from "react";
import "./index.scss";
import { TextField } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { ListItem } from "@mui/material";
import { Divider } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
function NavbarResponsive(props) {
  let history = useHistory();
  const listNavbar = [
    {
      itemName: "Sneakers",
      subItems: [
        {
          itemName: "Adidas",
        },
        {
          itemName: "Nike",
        },
        {
          itemName: "Jordan",
        },
        {
          itemName: "Vans",
        },
        {
          itemName: "Converse",
        },
        {
          itemName: "New Balance",
        },
        {
          itemName: "Rebook",
        },
        {
          itemName: "Sketcher",
        },
      ],
    },
    {
      itemName: "Clothing",
      subItems: [
        {
          itemName: "Adidas",
        },
        {
          itemName: "Nike",
        },
      ],
    },
    {
      itemName: "All Product",
    },
    {
      itemName: "Contact us",
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState("");
  const deleteActiveNavItem = () => {
    let toggles = document.querySelectorAll(".navbar-list__item .toggle");
    toggles.forEach((toggle) => {
      toggle.classList.remove("active");
    });
  };
  const handleClick = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteActiveNavItem();
    if (selectedIndex === index) {
      console.log();
      setSelectedIndex("");
      if (e.currentTarget.querySelector(".toggle") != null) {
        e.currentTarget.children[1].classList.remove("active");
      }
    } else {
      setSelectedIndex(index);
      if (e.currentTarget.querySelector(".toggle") != null) {
        e.currentTarget.children[1].classList.add("active");
      }
    }
  };

  const handleCloseModal = (e) => {
    document.querySelector(".navbar-responsive").classList.remove("active");
    document.querySelector("body").classList.remove("open-modal");
    // modalRoot.classList.toggle('active');
  };

  let [textSearch, setTextSearch] = useState("");
  let [productBySearch, setProductBySearch] = useState([]);

  let timeOutBySearch;
  const setTextSearchState = async (_textSearch) => {
    if (timeOutBySearch) {
      clearTimeout(timeOutBySearch);
    }

    timeOutBySearch = setTimeout(() => {
      setTextSearch(_textSearch);
    }, 800);
  };

  const getProductBySearch = async (_textSearch) => {
    const blackListText = ["doubleclick", "advert", "ad", "click"];

    try {
      if (_textSearch.trim() === "") {
        await setProductBySearch([]);
        return;
      }
      if (blackListText.includes(_textSearch.trim())) {
        await setProductBySearch(["Product Not Found"]);
        return;
      }

      await setProductBySearch(["loading"]);

      const res = await axios
        .get(`https://sneaker-api.onrender.com/search/${_textSearch}?count=10`)
        .catch((err) => { });

      let data = res?.data ?? undefined;

      if (data) {
        if (data === "Product Not Found") {
          await setProductBySearch(["Product Not Found"]);
          return;
        }

        await setProductBySearch(data);
      }
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    getProductBySearch(textSearch);
  }, [textSearch]);
  return (
    <div className="navbar-responsive">
      <div className="navbar-wrapper">
        <div className="navbar-header">
          <div className="navbar-control__close">
            <div
              className="btn-close"
              onClick={(e) => {
                handleCloseModal(e);
              }}
            ></div>
          </div>
        </div>
        <div className="navbar-search">
          <TextField
            id="search-product"
            label="Tìm kiếm"
            placeholder="Tìm kiếm"
            variant="standard"
            onChange={(e) => {
              setTextSearchState(e.currentTarget.value);
            }}
            InputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "25px" } }}
            FormHelperTextProps={{ style: { width: "500px" } }}
            sx={{ width: "100%" }}
          />
          <ul
            className="products-search"
            style={
              productBySearch?.length > 0
                ? {
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "4px",
                }
                : {}
            }
          >
            {productBySearch.map((prod, index) => {
              return prod === "Product Not Found" ? (
                <li className="products-search__item error">
                  <h2>{prod}</h2>
                </li>
              ) : prod === "loading" ? (
                <li className="products-search__item loading">
                  <div className="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </li>
              ) : (
                <li
                  className="products-search__item"
                  onClick={() => {
                    history.push(`/products/${prod.urlKey}`);
                  }}
                  key={`product-search-responsive-item-${index}`}
                >
                  <div className="product-search__item">
                    <div className="img">
                      <img src={prod.thumbnail} />
                    </div>
                    <div className="content">
                      <div className="content-brand">
                        <p>{prod.brand}</p>
                      </div>
                      <div className="content-productName">
                        <b>{prod.shoeName}</b>
                      </div>
                      <div className="content-color">
                        <b>{prod.colorway}</b>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <List className="navbar-list">
          {listNavbar.map((navbar, index) => (
            <div key={index}>
              <ListItem
                button={true}
                className="navbar-list__item"
                onClick={(e) => {
                  navbar.subItems !== undefined
                    ? handleClick(index, e)
                    : console.log();
                }}
              >
                <p>{navbar.itemName}</p>

                {navbar.subItems !== undefined ? (
                  <span className="toggle">
                    <span></span>
                    <span></span>
                  </span>
                ) : (
                  <div style={{ display: "none" }}></div>
                )}
              </ListItem>
              {navbar.subItems !== undefined ? (
                <Collapse
                  className="navbar-sublist"
                  in={index === selectedIndex}
                >
                  {navbar.subItems.map((item, indexSub) => (
                    <ListItem
                      button={true}
                      className="navbar-sublist__item"
                      key={indexSub}
                    >
                      {item.itemName}
                    </ListItem>
                  ))}
                </Collapse>
              ) : (
                <div style={{ display: "none" }}></div>
              )}
            </div>
          ))}
        </List>
      </div>
    </div>
  );
}

export default NavbarResponsive;
