import "./App.scss";
import Header from "./Components/Header";
import Collection from "./Pages/Collection";
import store from "./Stores/store"
import { connect, Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import DetailProduct from "./Pages/DetailProduct";
function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home></Home>
            </Route>
            <Route path="/home">
              <Home></Home>
            </Route>
            <Route path="/adidas">
              <h1>Adidas</h1>
            </Route>
            <Route path="/nike">
              <h1>Nike</h1>
            </Route>
            <Route path="/converse">
              <h1>Converse</h1>
            </Route>
            <Route path="/rebook">
              <h1>Rebook</h1>
            </Route>
            <Route path="/vans">
              <h1>Vans</h1>
            </Route>
            <Route path="/new-balance">
              <h1>New balance</h1>
            </Route>
            <Route path="/collections">
              <Collection></Collection>
            </Route>
            <Route path="/products/:productID">
              <DetailProduct></DetailProduct>
            </Route>
            <Route path="*">
              <h1>No match</h1>
            </Route>
          </Switch>
        </Router>
      </div>
    </Provider>

  );
}

export default App;
