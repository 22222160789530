import React, { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import "./index.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
function SearchModal(props) {
  let history = useHistory();
  // const goToLink = useCallback((urlKey) => {
  //   console.log(urlKey);
  //   history.push(`/products/${urlKey}`);
  // }, [history])

  const handleCloseModal = (e) => {
    let currentTarget = e.target;
    let modalRoot = document.querySelector(".search-modal");
    if (modalRoot.classList.contains("active")) {
      document.querySelector("body").classList.remove("open-modal");
      modalRoot.classList.remove("active");
    }
    // modalRoot.classList.toggle('active');
  };
  let [textSearch, setTextSearch] = useState("");
  let [productBySearch, setProductBySearch] = useState([]);

  let timeOutBySearch;
  const setTextSearchState = async (_textSearch) => {
    if (timeOutBySearch) {
      clearTimeout(timeOutBySearch);
    }

    timeOutBySearch = setTimeout(() => {
      setTextSearch(_textSearch);
    }, 800);
  };

  const getProductBySearch = async (_textSearch) => {
    console.log(_textSearch);
    const blackListText = ["doubleclick", "advert", "ad", "click"];

    try {
      if (_textSearch.trim() === "") {
        await setProductBySearch([]);
        return;
      }
      if (blackListText.includes(_textSearch.trim())) {
        await setProductBySearch(["Product Not Found"]);
        return;
      }

      await setProductBySearch(["loading"]);

      const res = await axios
        .get(`https://sneaker-api.onrender.com/search/${_textSearch}?count=10`)
        .catch((err) => { });

      let data = res?.data ?? undefined;

      if (data) {
        if (data === "Product Not Found") {
          await setProductBySearch(["Product Not Found"]);
          return;
        }

        await setProductBySearch(data);
      }
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    getProductBySearch(textSearch);
  }, [textSearch]);

  return (
    <div
      className="search-modal"
      onClick={(e) => {
        e.currentTarget.classList.remove("active");
      }}
    >
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <div className="container">
            <div className="modal-control__close">
              <div
                className="btn-close"
                data-modal-root="search-modal"
                onClick={(e) => {
                  handleCloseModal(e);
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-input__search">
            <div className="container">
              <TextField
                id="search-product"
                label="Tìm kiếm"
                placeholder="Tìm kiếm"
                variant="standard"
                onChange={(e) => {
                  setTextSearchState(e.currentTarget.value);
                }}
                InputProps={{ style: { fontSize: "25px" } }}
                InputLabelProps={{ style: { fontSize: "25px" } }}
                FormHelperTextProps={{ style: { width: "500px" } }}
                sx={{ width: "100%" }}
              />

              <ul
                className="products-search"
                style={
                  productBySearch?.length > 0
                    ? {
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                      borderRadius: "4px",
                    }
                    : {}
                }
              >
                {productBySearch.map((prod, index) => {
                  return prod === "Product Not Found" ? (
                    <li className="products-search__item error">
                      <h2>{prod}</h2>
                    </li>
                  ) : prod === "loading" ? (
                    <li className="products-search__item loading">
                      <div className="lds-ripple">
                        <div></div>
                        <div></div>
                      </div>
                    </li>
                  ) : (
                    <li
                      className="products-search__item"
                      onClick={() => {
                        history.push(`/products/${prod.urlKey}`);
                      }}
                      key={`product-search-responsive-item-${index}`}
                    >
                      <div className="product-search__item">
                        <div className="img">
                          <img src={prod.thumbnail} />
                        </div>
                        <div className="content">
                          <div className="content-brand">
                            <p>{prod.brand}</p>
                          </div>
                          <div className="content-productName">
                            <b>{prod.shoeName}</b>
                          </div>
                          <div className="content-color">
                            <b>{prod.colorway}</b>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchModal;
