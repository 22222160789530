import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "./index.scss";
import ProductItemSwiper from "../ProductItemSwiper";
import { useSelector } from "react-redux";

//install modules
SwiperCore.use([Pagination, Navigation]);

function SwiperListProductItem(props) {
  const { listProduct } = props;
  return (
    <Swiper
      spaceBetween={30}
      // slidesPerGroup={3}
      loop={true}
      // loopFillGroupWithBlank={true}
      pagination={{
        clickable: true,
        type: "progressbar",
      }}
      className="product-swiper"
      // autoplay={{ delay: 1000 }}
      breakpoints={{
        100: {
          slidesPerView: 1,
          direction: 'horizontal',
          slidesPerGroup: 1,
        },
        767: {
          slidesPerView: 2,
          direction: 'horizontal',
          slidesPerGroup: 1,
        },
        991: {
          slidesPerView: 3,
          direction: 'horizontal',
          slidesPerGroup: 1,
        }
      }}
    >
      {listProduct.map((item, index) => {
        return (
          <SwiperSlide key={`swiper-slide-${index}`}>
            <ProductItemSwiper key={`product-item-${index}`} data={item} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default SwiperListProductItem;
