import React from 'react'
import './index.scss'
function BreadCumb(props) {
    const { shoeName } = props;
    return (
        <div className="breadcumb">
            <div className="container">
                HOME / PRODUCTS / {shoeName}
            </div>
        </div>
    )
}

export default BreadCumb
