import { createSlice, current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const initialState = [];

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addProduct: (state, action) => {
      if (state.length === 0) {
        let object = {
          count: 1,
          product: action.payload,
        };
        state.push(object);
        toast.success(<div><div>{action.payload.shoeName}</div><br /><div>Size {action.payload.sizeSelected}</div><br /> <div>The product had added in your cart.</div></div>)
        return state;
      }

      // find product in state
      let isExist = false;
      state.flat().forEach((item, index) => {
        // if (item.product._id === action.payload._id) {
        //   item.count += 1;
        //   isExist = true;
        // }
        if (JSON.stringify(item.product) === JSON.stringify(action.payload)) {
          item.count += 1;
          isExist = true;
        }
      });

      // if this product not exists in state
      if (isExist !== false) {
        toast.success(<div><div>{action.payload.shoeName}</div><br /><div>Size {action.payload.sizeSelected}</div><br /> <div>The product had added in your cart.</div></div>)
        return state;
      }

      // add this product into state
      let object = {
        count: 1,
        product: action.payload,
      };
      state.push(object);

      toast.success(<div><div>{action.payload.shoeName}</div><br /><div>Size {action.payload.sizeSelected}</div><br /> <div>The product had added in your cart.</div></div>)

      console.log(current(state));
      return state;
    },
    removeProduct: (state, action) => {
      if (state.length === 0) {
        return;
      }

      state = state.filter((item) => item._id !== action.payload._id);

      return state;
    },
    subQuantityProduct: (state, action) => {
      state.flat().forEach((item, index) => {
        if (item.product._id === action.payload._id) {
          item.count -= 1;
        }
      });

      console.log(state);

      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addProduct, removeProduct } = cartSlice.actions;

export default cartSlice.reducer;
