import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    isActive: false,
};

export const loadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
        setIsActiveLoading: (state, action) => {
            console.log(action.payload)
            // state = !state;
            state = action.payload;
            return state;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setIsActiveLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
