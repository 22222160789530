import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Banner from "../../Components/Banner";
import CardEffect from "../../Components/CardEffect";
import LineWave from "../../Components/LineWave";
import CreditCard from "../../Components/CreditCard";
import ListProductItem from "../../Components/ListProductItem";
import "./index.scss";
import SwiperListProductItem from "../../Components/SwiperListProductItem";
import axios from "axios";
import Loading from "../../Components/Loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Home() {
  const [productAdidas, setProductAdidas] = useState([]);
  const [productNike, setProductNike] = useState([]);
  const [productConverse, setProductConverse] = useState([]);
  const [productNewRelease, setProductNewRelease] = useState([]);
  const [product, setProduct] = useState([]);

  let isFirst = false;

  const handleRemoveActiveTabTable = () => {
    let tabsTable = document.querySelectorAll(
      ".tab-products .tabs .tabs-table__item"
    );

    tabsTable.forEach((table) => {
      table.classList.remove("active");
    });
  };

  const handleTabTable = (target) => {
    let tabsTable = document.querySelectorAll(
      ".tab-products .tabs .tabs-table__item"
    );

    tabsTable.forEach((table) => {
      let fromTarget = table.getAttribute("data-target");
      console.log(fromTarget);
      if (fromTarget === target) {
        handleRemoveActiveTabTable();
        table.classList.add("active");
        return;
      }
    });
  };

  const handleTabProduct = () => {
    let tabs = document.querySelectorAll(
      ".tab-products .tabs .tabs-header__item"
    );

    tabs.forEach((tab) => {
      tab.addEventListener("click", (e) => {
        let target = e.currentTarget;
        handleRemoveActiveTabProduct();

        target.classList.add("active");

        let targetAttr = target.getAttribute("data-target");

        handleTabTable(targetAttr);
      });
    });
  };

  const handleRemoveActiveTabProduct = () => {
    let tabs = document.querySelectorAll(
      ".tab-products .tabs .tabs-header__item"
    );

    tabs.forEach((tab) => {
      if (tab.classList.contains("active")) {
        tab.classList.remove("active");
      }
    });
  };

  const getDataFromAPI = () => {
    axios.get("https://sneaker-api.onrender.com").then((res) => {
      if (res?.data) {
        const { data } = res;
        setProduct(data);
      }
    });
  };



  const setDataByFilter = async () => {
    axios.get(`https://sneaker-api.onrender.com/search/adidas`).then((res) => {
      setProductAdidas(res?.data ?? []);
    });
    axios.get(`https://sneaker-api.onrender.com/search/nike`).then((res) => {
      setProductNike(res?.data ?? []);
    });
    axios.get(`https://sneaker-api.onrender.com/search/converse`).then((res) => {
      setProductConverse(res?.data ?? []);
    });
  };

  const setDataNewReleases = () => {
    axios.get(`https://sneaker-api.onrender.com/home`).then((res) => {
      setProductNewRelease(res?.data ?? []);
    })
  }

  useEffect(() => {
    getDataFromAPI();
    setDataByFilter();
    setDataNewReleases();
  }, []);

  useEffect(() => {
    let tabs = document.querySelectorAll(
      ".tab-products .tabs .tabs-header__item"
    );

    if (isFirst === false) {
      console.log("initial", isFirst);
      tabs.forEach((tab) => {
        tab.addEventListener("click", (e) => {
          if (isFirst.current) {
            e.stopPropagation();
            e.preventDefault();
            return;
          }
          console.log(isFirst);
          isFirst = true;

          let target = e.currentTarget;
          handleRemoveActiveTabProduct();

          target.classList.add("active");

          let targetAttr = target.getAttribute("data-target");

          handleTabTable(targetAttr);
        });
      });
      return;
    }

    handleTabProduct();
  }, [isFirst]);

  return (
    <div>
      <Loading></Loading>
      <Header></Header>
      <Banner initialIndex="1"></Banner>
      <CreditCard></CreditCard>
      <LineWave></LineWave>
      <CardEffect></CardEffect>
      <div className="section hot-deal">
        <div className="section-product">
          <div className="container">
            <h2>HOT DEALS</h2>
          </div>
        </div>
        <div className="product-list">
          <div className="container">
            <ListProductItem
              listProduct={product.slice(0, 8)}
            ></ListProductItem>
          </div>
        </div>
      </div>
      <div className="section tab-products">
        <div className="container">
          <div className="tabs">
            <ul className="tabs-header">
              <li className="tabs-header__item " data-target="adidas">
                <h3>Adidas</h3>
              </li>
              <li className="tabs-header__item active" data-target="nike">
                <h3>Nike</h3>
              </li>
              <li className="tabs-header__item " data-target="converse">
                <h3>Converse</h3>
              </li>
              <li className="tabs-header__item glider"></li>
            </ul>
            <div className="tabs-table">
              <div className="tabs-table__item" data-target="adidas">
                <ListProductItem
                  listProduct={productAdidas.slice(0, 8)}
                ></ListProductItem>
              </div>
              <div className="tabs-table__item active" data-target="nike">
                <ListProductItem
                  listProduct={productNike.slice(0, 8)}
                ></ListProductItem>
              </div>
              <div className="tabs-table__item" data-target="converse">
                <ListProductItem
                  listProduct={productConverse.slice(0, 8)}
                ></ListProductItem>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section adidas-products">
        <div className="section-product">
          <div className="container">
            <h2>NEW RELEASES</h2>
          </div>
        </div>
        <div className="swiper">
          <div className="container">
            <SwiperListProductItem listProduct={productNewRelease} />
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Home;
