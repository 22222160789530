import { configureStore } from '@reduxjs/toolkit'
import cartReducer from "./Slices/cart.slice"
import addCartModalReducer from "./Slices/add-cart-modal"
import loadingReducer from "./Slices/loading.slice"


const store = configureStore({
    reducer: {
        cart: cartReducer,
        addCartModal: addCartModalReducer,
        loading: loadingReducer,
    }
});

export default store;