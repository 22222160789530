import { createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isActive: false,
    productInfo: {},
};

export const addCartModalSlice = createSlice({
    name: "addCartModal",
    initialState,
    reducers: {
        setIsActive: (state, action) => {

            state = action.payload;
            // state = !state;
            return state;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setIsActive } = addCartModalSlice.actions;

export default addCartModalSlice.reducer;
