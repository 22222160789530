import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import { TextField, Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import SearchModal from "../SearchModal";
import NavbarResponsive from "../NavbarResponsive";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import AddToCartModal from "../AddToCartModal";
const Header = (props) => {
  let [textSearch, setTextSearch] = useState("");
  let [productBySearch, setProductBySearch] = useState([]);
  const cartList = useSelector((state) => state.cart);

  let history = useHistory();
  const goToHome = useCallback(() => {
    history.push(`/home`);
  }, [history]);

  let param = useRouteMatch();

  const openSearchModal = () => {
    let modalSearchBox = document.querySelector(".search-modal");
    if (!modalSearchBox.classList.contains("active")) {
      document.querySelector("body").classList.add("open-modal");
      modalSearchBox.classList.add("active");
    }
  };

  const openHamburger = () => {
    document.querySelector(".navbar-responsive").classList.add("active");
    document.querySelector("body").classList.add("open-modal");
  };

  let timeOutBySearch;
  const setTextSearchState = async (_textSearch) => {
    if (timeOutBySearch) {
      clearTimeout(timeOutBySearch);
    }

    timeOutBySearch = setTimeout(() => {
      setTextSearch(_textSearch);
    }, 800);
  };

  const getProductBySearch = async (_textSearch) => {
    console.log(_textSearch);
    const blackListText = ["doubleclick", "advert", "ad", "click"];

    try {
      if (_textSearch.trim() === "") {
        await setProductBySearch([]);
        return;
      }
      if (blackListText.includes(_textSearch.trim())) {
        await setProductBySearch(["Product Not Found"]);
        return;
      }

      await setProductBySearch(["loading"]);

      const res = await axios
        .get(`https://sneaker-api.onrender.com/search/${_textSearch}?count=10`)
        .catch((err) => { });

      let data = res?.data ?? undefined;

      if (data) {
        if (data === "Product Not Found") {
          await setProductBySearch(["Product Not Found"]);
          return;
        }

        await setProductBySearch(data);
      }
    } catch (err) {
      return;
    }
  };

  useEffect(() => {
    getProductBySearch(textSearch);
  }, [textSearch]);

  return (
    <div className="header">
      <div className="header-top">
        <div className="container">
          <div className="header-top__wrapper">
            <div className="header-top__left">
              <span>Sản phẩm chính hãng 100%</span>
              <div className="seperate"></div>
              <span>
                Hotline: <b>0789163351</b>
              </span>
            </div>
            <div className="header-top__right">
              <Link to="login" className="login">
                <div className="login__wrapper">
                  <img
                    className="img-hover"
                    src="/img/login_black.svg"
                    alt=""
                  />
                  <img
                    className="img-default"
                    src="/img/login_white.svg"
                    alt=""
                  />
                </div>
                Đăng nhập
              </Link>
              <Link to="cart" className="cart">
                <div className="cart__wrapper">
                  <img className="img-hover" src="/img/cart_black.svg" alt="" />
                  <img
                    className="img-default"
                    src="/img/cart_white.svg"
                    alt=""
                  />
                  <span className="cart-total-item">
                    {cartList.reduce((prev, curr, index) => {
                      return prev + curr.count;
                    }, 0)}
                  </span>
                </div>
                Giỏ hàng
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="header-main">
        <div className="container">
          <div className="header-logo">
            <Link
              to="/home"
              onClick={() => {
                goToHome();
              }}
            >
              <img src="/img/logo.png" alt="" />
            </Link>
          </div>
          <ul className="header-nav">
            <li className="header-nav__item">
              <Link className="header-nav__link" to="/sneakers">
                Sneaker
              </Link>
              <div className="header-nav__dropdown">
                <div className="dropdown-col">
                  <div className="dropdown-col__item">
                    <Link to="/adidas">
                      <span>Adidas</span>
                    </Link>
                  </div>
                  <div className="dropdown-col__item">
                    <Link to="/rebook">
                      <span>Rebook</span>
                    </Link>
                  </div>
                </div>
                <div className="dropdown-col">
                  <div className="dropdown-col__item">
                    <Link to="/nike">
                      <span>Nike</span>
                    </Link>
                  </div>
                  <div className="dropdown-col__item">
                    <Link to="/new-balance">
                      <span>New balance</span>
                    </Link>
                  </div>
                </div>
                <div className="dropdown-col">
                  <div className="dropdown-col__item">
                    <Link to="/jordan">
                      <span>Jordan</span>
                    </Link>
                  </div>
                  <div className="dropdown-col__item">
                    <Link to="/converse">
                      <span>Converse</span>
                    </Link>
                  </div>
                </div>
                <div className="dropdown-col">
                  <div className="dropdown-col__item">
                    <Link to="/vans">
                      <span>Vans</span>
                    </Link>
                  </div>
                  <div className="dropdown-col__item">
                    <Link to="/sketcher">
                      <span>Sketcher</span>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li className="header-nav__item">
              <Link className="header-nav__link" to="/clothing">
                Clothing
              </Link>
            </li>
            <li className="header-nav__item">
              <Link className="header-nav__link" to="/all-product">
                All product
              </Link>
            </li>
            <li className="header-nav__item">
              <Link className="header-nav__link" to="/contact">
                Contact us
              </Link>
            </li>
          </ul>

          <Box
            className="search-box"
            sx={{ display: "flex", alignItems: "flex-end", width: "300px" }}
          >
            <TextField
              id="search-product"
              label="Tìm kiếm"
              variant="standard"
              onChange={(e) => {
                setTextSearchState(e.currentTarget.value);
              }}
              sx={{ width: "100%" }}
            />

            <ul
              className="products-search"
              style={
                productBySearch?.length > 0
                  ? {
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    borderRadius: "4px",
                  }
                  : {}
              }
            >
              {productBySearch.map((prod, index) => {
                return prod === "Product Not Found" ? (
                  <li key={`product-search-responsive-item-${index}`} className="products-search__item error">
                    <h2>{prod}</h2>
                  </li>
                ) : prod === "loading" ? (
                  <li key={`product-search-responsive-item-${index}`} className="products-search__item loading">
                    <div className="lds-ripple">
                      <div></div>
                      <div></div>
                    </div>
                  </li>
                ) : (
                  <li
                    className="products-search__item"
                    onClick={() => {
                      history.push(`/products/${prod.urlKey}`);
                    }}
                    key={`product-search-responsive-item-${index}`}
                  >
                    <div className="product-search__item">
                      <div className="img">
                        <img src={prod.thumbnail} />
                      </div>
                      <div className="content">
                        <div className="content-brand">
                          <p>{prod.brand}</p>
                        </div>
                        <div className="content-productName">
                          <b>{prod.shoeName}</b>
                        </div>
                        <div className="content-color">
                          <b>{prod.colorway}</b>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </Box>

          <Box className="responsive">
            <Box className="hamburger-responsive">
              <div
                className="hamburger"
                onClick={() => {
                  openHamburger();
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Box>
            <Box
              className="search-box-responsive"
              onClick={() => {
                openSearchModal();
              }}
            >
              <svg
                id="Capa_1"
                height="25"
                viewBox="0 0 461.516 461.516"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
              >
                <g>
                  <path d="m185.746 371.332c41.251.001 81.322-13.762 113.866-39.11l122.778 122.778c9.172 8.858 23.787 8.604 32.645-.568 8.641-8.947 8.641-23.131 0-32.077l-122.778-122.778c62.899-80.968 48.252-197.595-32.716-260.494s-197.594-48.252-260.493 32.716-48.252 197.595 32.716 260.494c32.597 25.323 72.704 39.06 113.982 39.039zm-98.651-284.273c54.484-54.485 142.82-54.486 197.305-.002s54.486 142.82.002 197.305-142.82 54.486-197.305.002c-.001-.001-.001-.001-.002-.002-54.484-54.087-54.805-142.101-.718-196.585.239-.24.478-.479.718-.718z" />
                </g>
              </svg>
            </Box>
          </Box>
        </div>
      </div>
      <SearchModal active="true"></SearchModal>
      <NavbarResponsive></NavbarResponsive>
      <AddToCartModal isActive={true} />

    </div>
  );
};

export default Header;
