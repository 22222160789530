import React from "react";
import "./index.scss";
function CardEffect(props) {
  return (
    <div className="box-list">
      <div className="container">
        <div className="box">
          <img src="./img/supreme2.png" alt="" />
        </div>
        <div className="box">
          <img src="./img/jordan4_2.png" alt="" />
        </div>
        <div className="box">
          <img src="./img/backpack_adidas.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default CardEffect;
