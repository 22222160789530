import React from "react";
import ProductItem from "../ProductItem";
import "./index.scss";

function ListProductItem(props) {
  const { listProduct } = props;

  return (
    <div className="product-list">
      <div className="container">
        {listProduct.map((item, index) => {
          return <ProductItem data={item} key={index} />;
        })}
      </div>
    </div>
  );
}

export default ListProductItem;
