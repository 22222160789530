import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../../Components/Header'
import BreadCumb from '../../Components/BreadCumb'
import ProductInfo from '../../Components/ProductInfo'
import { useDispatch } from 'react-redux';
import { setIsActiveLoading } from '../../Stores/Slices/loading.slice';
import Loading from '../../Components/Loading';
import { ToastContainer } from 'react-toastify';
function DetailProduct() {
    let { productID } = useParams();
    let [productDetail, setProductDetail] = useState([]);
    let [productInfo, setProductInfo] = useState({});
    let [imageList, setImageList] = useState([])
    // let [nameProduct, setNameProduct] = useState('')
    // let [productCode, setProductCode] = useState('')
    // let [releaseDate, setReleaseDate] = useState('')
    // let [descProduct, setDescProduct] = useState('')
    // let [retailPrice, setRetailPrice] = useState(0)
    // let [colorway, setColorWay] = useState('')
    // let [brand, setBrand] = useState('')
    // let [info, setInfo] = useState({});
    let dispatch = useDispatch();

    const getProductByID = async () => {
        return await axios.get(`https://sneaker-api.onrender.com/search/${productID}`).then((res) => {
            dispatch(setIsActiveLoading(false))
            return res?.data !== 'Product Not Found' ? res.data : [];
        });
    }

    const getListImagesByStyleID = async (styleID) => {
        return await axios.get(`https://sneaker-api.onrender.com/id/${styleID}/prices`).then((res) => {
            setImageList(res.data.imageLinks);
            // setNameProduct(res.data.shoeName)
            // setProductCode(res.data.styleID)
            // setReleaseDate(res.data.releaseDate)
            // setDescProduct(res.data.description)
            // setBrand(res.data.brand)
            // setRetailPrice(res.data.retailPrice)
            // setColorWay(res.data.colorway);

            setProductInfo(res.data);
            return res.data;
        })
    }

    const getListSizesByStyleID = async (styleID) => {
        return await axios.get(`https://sneaker-api.onrender.com/id/${styleID}/prices`).then((res) => {
            return res.data;
        })
    }

    useEffect(() => {

        dispatch(setIsActiveLoading(true))

        getProductByID().then((res) => {
            setProductDetail(res);

            let styleID = res[0].styleID || 'GZ5990';

            // getListSizesByStyleID(styleID).then((res) => {
            //     console.log(res)
            //     setInfo(res);
            // })

            getListImagesByStyleID(styleID);

        });
    }, [])

    return (
        <div>
            <ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Loading></Loading>
            <Header></Header>

            <BreadCumb shoeName={productDetail.length != 0 ? productDetail[0].shoeName : ''}></BreadCumb>

            {/* <ProductInfo productInfo={productInfo} colorway={colorway} retailPrice={retailPrice} brand={brand} descProduct={descProduct} releaseDate={releaseDate} productCode={productCode} nameProduct={nameProduct} imageList={imageList}></ProductInfo> */}
            <ProductInfo imageList={imageList} productInfo={productInfo}></ProductInfo>

        </div>
    )
}

export default DetailProduct
