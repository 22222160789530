import React from "react";
import { useRouteMatch } from "react-router";
import Header from "../../Components/Header";
function Collection(props) {
  let { path, url } = useRouteMatch();
  
  const { brand } = props;
  return (
    <div>
      <Header />
    </div>
  );
}

export default Collection;
